<template>
  <filter-layout-popup
    title="item 필터"
    @close="$emit('close')"
    @reset="handleResetAll"
    @submit="$emit('input', filteredItems)"
  >
    <div class="pop_header btwlist">
      <p class="con w50">
        <input id="soldOut" v-model="filteredItems.soldOut" type="checkbox" />
        <label for="soldOut" class="chkbox">품절제외</label>
      </p>
    </div>
    <side-tab v-if="filterItems" :options="options" value="category">
      <side-tab-item key="category">
        <ks-category-check-box
          v-model="filterItems"
          :filteredItems="filteredItems"
        />
      </side-tab-item>

      <side-tab-item key="color">
        <li v-for="(item, idx) in filterItems.color" :key="item.key">
          <ks-check-box
            v-model="filteredItems.color[idx]"
            :item="item"
            name="color"
            passValueKey="label"
          >
            <span
              class="color_round"
              :style="`background-color:${item.code}`"
            />
          </ks-check-box>
        </li>
      </side-tab-item>

      <side-tab-item key="price">
        <div class="reset_pr">
          <button type="button" @click="handleClickResetPrice">
            가격초기화
          </button>
        </div>
        <p style="padding-top: 20px" class="price-text">
          {{ `${filteredItems.price[0]} ~ ${filteredItems.price[1]}` }}
        </p>
        <div v-if="maxPrice" style="margin: 20px 20px">
          <multi-range v-model="filteredItems.price" :max="maxPrice" />
        </div>
      </side-tab-item>

      <side-tab-item key="concept">
        <li v-for="(item, idx) in filterItems.concept" :key="item.key">
          <ks-check-box
            v-model="filteredItems.concept[idx]"
            :item="item"
            name="concept"
            passValueKey="label"
          />
        </li>
      </side-tab-item>

      <side-tab-item key="age">
        <li v-for="(item, idx) in filterItems.age" :key="item.key">
          <ks-check-box
            v-model="filteredItems.age[idx]"
            :item="item"
            name="age"
            passValueKey="label"
          />
        </li>
      </side-tab-item>

      <side-tab-item key="style">
        <li v-for="(item, idx) in filterItems.style" :key="item.key">
          <ks-check-box
            v-model="filteredItems.style[idx]"
            :item="item"
            name="style"
            passValueKey="label"
          />
        </li>
      </side-tab-item>
      <!-- <side-tab-item key="size">
        <li v-for="(item, idx) in filterItems.size" :key="item.key">
          <ks-check-box v-model="filteredItems.size[idx]" :item="item" name="size" />
        </li>
      </side-tab-item> -->
    </side-tab>
  </filter-layout-popup>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import SideTab from '../../../components/ui/SideTab/SideTab.vue';
import SideTabItem from '../../../components/ui/SideTab/SideTabItem.vue';
import filterItems from '../../../consts/FilterItems';
import KsCheckBox from '../../../components/Inputs/KsCheckBox/KsCheckBox.vue';
import KsCategoryCheckBox from '@/components/Inputs/KsCheckBox/KsCategoryCheckBox.vue';
import FilterLayoutPopup from '../../../components/popups/FilterLayoutPopup.vue';
import MultiRange from '../../../components/Inputs/MultiRange/MultiRange.vue';
import ProductAPI from '../../../apis/ProductAPI';

export default {
  props: ['value', 'tabOptions'],
  components: {
    SideTab,
    SideTabItem,
    KsCheckBox,
    KsCategoryCheckBox,
    FilterLayoutPopup,
    MultiRange,
  },
  setup(props) {
    const state = reactive({
      filteredItems: {
        category1: {},
        category2: {},
        category3: {},
        concept: [],
        style: [],
        age: [],
        color: [],
        price: [],
        soldOut: false,
      },
      filterItems: null,
      maxPrice: null,
    });
    console.log('ItemsFilterPopup');
    console.log(props.tabOptions);
    console.log(props.value);
    Object.keys(props.value).forEach(key => {
      const value = props.value[key];
      console.log(
        `key: ${key}, value: ${JSON.stringify(
          value,
        )}, typeof value: ${typeof value}`,
      );
      state.filteredItems[key] = value;
    });

    console.log(state.filteredItems);
    const loadOptions = async () => {
      state.filterItems = await filterItems.getItemFilterOptions();
      console.log('loadOptions');
      console.log(state.filterItems);
    };

    const loadMaxPrice = async () => {
      const { data } = await ProductAPI.getItemsMaxPrice();
      const maxPrice = Number(data.result_data);

      state.maxPrice = maxPrice;

      if (!props.value.price) {
        state.filteredItems.price = [0, maxPrice];
      }
    };

    loadOptions();

    loadMaxPrice();

    return {
      ...toRefs(state),
      options:
        props.tabOptions === 'ItemsView'
          ? filterItems.itemsTabOptions
          : filterItems.brandDetailTabOptions,
      handleResetAll() {
        state.filteredItems = {
          category1: {},
          category2: {},
          category3: {},
          concept: [],
          style: [],
          age: [],
          color: [],
          price: [],
          soldOut: false,
        };
      },
      handleClickResetPrice() {
        state.filteredItems.price = [0, state.maxPrice];
      },
    };
  },
};
</script>

<style>
.price-text {
  font-size: 12px;
  margin-bottom: 8px;
}
.reset_pr {
  text-align: right;
  margin-top: 8px;
  margin-right: 14px;
}

.reset_pr button {
  text-align: right;
  min-width: 100px;
  line-height: 30px;
  padding-right: 10px;
  background: #efefef url(~@/assets/images/design/arrow-clockwise-light.svg)
    no-repeat left 10px center;
  font-size: 12px;
}
</style>
