<template>
  <div>
    <li
      v-for="(category1_item, category1_idx) in filterItems.category"
      :key="category1_item.key"
      class="wo_category on"
    >
      <label class="chkbox font2 color37">
        <input
          v-model="
            filteredItems.category1[
              `${category1_item.key}#${category1_item.label}`
            ]
          "
          type="checkbox"
          :name="`category1-${category1_item.key}`"
          @click="
            $emit('click', $event);
            handleMe(1, category1_item);
            handleChildren(1, category1_item);
          "
          @change="handleChangeCheck"
        />
        <slot />
        {{ category1_item.label }}
      </label>
      <button
        type="button"
        :class="`list_view ${category1_item.folding ? 'on' : ''}`"
        @click="handleClickFoldButton(category1_item)"
      ></button>
      <ul v-if="category1_item.folding" class="depth2">
        <li
          v-for="(category2_item, category2_idx) in category1_item.children"
          :key="category2_item.key"
        >
          <label class="chkbox font2 color37">
            <input
              v-model="
                filteredItems.category2[
                  `${category2_item.key}#${category2_item.label}`
                ]
              "
              :checked="true"
              type="checkbox"
              :name="`category2-${category2_item.key}`"
              @click="
                $emit('click', $event);
                handleMe(2, category2_item);
                handleParent(2, category2_item, category1_idx);
                handleChildren(2, category2_item);
              "
              @change="handleChangeCheck"
            />
            <slot />
            {{ category2_item.label }}
          </label>
          <button
            type="button"
            :class="`list_view ${category2_item.folding ? 'on' : ''}`"
            @click="handleClickFoldButton(category2_item)"
          ></button>
          <ul v-if="category2_item.folding" class="depth3">
            <li
              v-for="category3_item in category2_item.children"
              :key="category3_item.key"
            >
              <label class="chkbox font2 color37">
                <input
                  v-model="
                    filteredItems.category3[
                      `${category3_item.key}#${category3_item.label}`
                    ]
                  "
                  type="checkbox"
                  :name="`category3-${category3_item.key}`"
                  @click="
                    $emit('click', $event);
                    handleMe(3, category3_item);
                    handleParent(
                      3,
                      category3_item,
                      category2_idx,
                      category1_idx,
                    );
                  "
                  @change="handleChangeCheck"
                />
                <slot />
                {{ category3_item.label }}
              </label>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </div>
</template>

<script>
import { ref, watch } from 'vue-demi';
export default {
  props: ['value', 'filteredItems'],
  name: 'KsCategoryCheckBox',
  components: {},
  setup(props) {
    console.log('KsCategoryCheckBox');
    console.log(props);
    const filterItems = ref(props.value);
    const filteredItems = ref(props.filteredItems);
    console.log('filterItems');
    console.log(filterItems);
    console.log('filteredItems');
    console.log(filteredItems);

    watch(
      () => props.value,
      () => {
        filterItems.value = props.value;
      },
    );

    return {
      filterItems,
      handleClickFoldButton(item) {
        console.log(filteredItems);
        console.log('handleClickFoldButton');
        console.log(item);
        item.folding = !item.folding;
      },
      handleChangeCheck() {
        console.log('handleChangeCheck');
      },
      handleParent(depth, item, parent_idx, parent_of_parent_idx) {
        console.log('handleParent');
        console.log(item);
        console.log(item.cca_parent);
        console.log(parent_idx);
        console.log(parent_of_parent_idx);
        const childKeyLabel = item.key + '#' + item.label;
        console.log('childKeyLabel');
        console.log(childKeyLabel);
        if (depth === 2) {
          console.log('depth2');
          const parentKeyLabel =
            props.value.category[parent_idx].key +
            '#' +
            props.value.category[parent_idx].label;
          console.log('parentKeyLabel');
          console.log(parentKeyLabel);
          if (props.filteredItems.category1[parentKeyLabel]) {
            // 부모가 체크 되어 있을때 자신이 체크를 해제하면 부모도 해제함
            props.filteredItems.category1[parentKeyLabel] =
              props.filteredItems.category2[childKeyLabel];
          } else {
            // 부모가 체크 해제되어 있을때 자신이 체크를 하면 형제들이 모두 체크 되어 있으면 부모도 체크함
            const origin = props.value.category[parent_idx].children;
            console.log('origin');
            console.log(origin);
            const originIdAndLabel = [];
            for (const category of origin) {
              originIdAndLabel.push(category.cca_id + '#' + category.label);
            }
            let parentChecked = true;
            for (const key of originIdAndLabel) {
              if (!props.filteredItems.category2[key]) {
                parentChecked = false;
              }
            }
            props.filteredItems.category1[parentKeyLabel] = parentChecked;
          }
        } else if (depth === 3) {
          console.log('depth3');
          const parent =
            props.value.category[parent_of_parent_idx].children[parent_idx];
          const parentKeyLabel = parent.key + '#' + parent.label;
          console.log(
            parentKeyLabel,
            props.filteredItems.category2[parentKeyLabel],
            childKeyLabel,
            props.filteredItems.category3[childKeyLabel],
          );
          if (props.filteredItems.category2[parentKeyLabel]) {
            // 부모가 체크 되어 있을때 자신이 체크를 해제하면 부모도 해제함
            props.filteredItems.category2[parentKeyLabel] =
              props.filteredItems.category3[childKeyLabel];
          } else {
            // 부모가 체크 해제되어 있을때 자신이 체크를 해서 형제들이 모두 체크 되어 있으면 부모도 체크함
            const origin =
              props.value.category[parent_of_parent_idx].children[parent_idx]
                .children;
            console.log('origin');
            console.log(origin);
            const originIdAndLabel = [];
            for (const category of origin) {
              originIdAndLabel.push(category.cca_id + '#' + category.label);
            }
            let parentChecked = true;
            for (const key of originIdAndLabel) {
              if (!props.filteredItems.category3[key]) {
                parentChecked = false;
              }
            }
            props.filteredItems.category2[parentKeyLabel] = parentChecked;
          }
          const parentOfParentKeyLabel =
            props.value.category[parent_of_parent_idx].key +
            '#' +
            props.value.category[parent_of_parent_idx].label;
          console.log(
            parentOfParentKeyLabel,
            props.filteredItems.category1[parentOfParentKeyLabel],
            childKeyLabel,
            props.filteredItems.category3[childKeyLabel],
          );
          if (props.filteredItems.category1[parentOfParentKeyLabel]) {
            // 부모의 부모가 체크 되어 있을때 자신이 체크를 해제하면 부모도 해제함
            props.filteredItems.category1[parentOfParentKeyLabel] =
              props.filteredItems.category3[childKeyLabel];
          } else {
            // 부모의 부모가 체크 해제되어 있을때 자식이 체크되어 부모의 형제까지 체크되면 부모도 체크함
            const origin = props.value.category[parent_idx].children;
            console.log('origin');
            console.log(origin);
            const originIdAndLabel = [];
            for (const category of origin) {
              originIdAndLabel.push(category.cca_id + '#' + category.label);
            }
            let parentChecked = true;
            for (const key of originIdAndLabel) {
              console.log(key);
              console.log(props.filteredItems.category2[key]);
              if (!props.filteredItems.category2[key]) {
                parentChecked = false;
              }
            }
            props.filteredItems.category1[parentOfParentKeyLabel] =
              parentChecked;
          }
        }
        this.$forceUpdate();
      },
      handleMe(depth, item) {
        console.log('handleMe');
        const keyLabel = item.key + '#' + item.label;
        if (depth === 1) {
          props.filteredItems.category1[keyLabel] =
            !props.filteredItems.category1[keyLabel];
        }
        if (depth === 2) {
          props.filteredItems.category2[keyLabel] =
            !props.filteredItems.category2[keyLabel];
        }
        if (depth === 3) {
          props.filteredItems.category3[keyLabel] =
            !props.filteredItems.category3[keyLabel];
        }
        this.$forceUpdate();
      },
      handleChildren(depth, item) {
        console.log('handleChildren');
        console.log(item);
        console.log('filteredItems');
        console.log(props.filteredItems);
        const parentKeyLabel = item.key + '#' + item.label;
        if (item.children && item.children.length > 0) {
          // 자식의 key#label 찾기
          console.log('handleChildren child');
          for (const child of item.children) {
            const childKeyLabel = child.key + '#' + child.label;
            if (depth === 1) {
              console.log(
                parentKeyLabel,
                props.filteredItems.category1[parentKeyLabel],
                childKeyLabel,
                props.filteredItems.category2[childKeyLabel],
              );
              props.filteredItems.category2[childKeyLabel] =
                props.filteredItems.category1[parentKeyLabel];
              if (child.children && child.children.length > 0) {
                console.log('handleChildren child of child');
                for (const childOfChild of child.children) {
                  const childOfChildKeyLabel =
                    childOfChild.key + '#' + childOfChild.label;
                  console.log(childOfChildKeyLabel);
                  props.filteredItems.category3[childOfChildKeyLabel] =
                    props.filteredItems.category1[parentKeyLabel];
                }
              }
            } else if (depth === 2) {
              console.log(
                parentKeyLabel,
                props.filteredItems.category2[parentKeyLabel],
                childKeyLabel,
                props.filteredItems.category3[childKeyLabel],
              );
              props.filteredItems.category3[childKeyLabel] =
                props.filteredItems.category2[parentKeyLabel];
            }
          }
        }
        console.log('category1 result');
        console.log(props.filteredItems.category1);
        console.log('category2 result');
        console.log(props.filteredItems.category2);
        console.log('category3 result');
        console.log(props.filteredItems.category3);
        this.$forceUpdate();
      },
    };
  },
};
</script>

<style>
.chkbox {
  display: flex;
  align-items: center;
}
.chkbox input {
  margin-right: 10px;
}
</style>
