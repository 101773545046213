<template>
  <div class="middle">
    <div class="multi-range-slider">
      <!-- 진짜 슬라이더 -->
      <input
        ref="leftRef"
        v-model="values[0]"
        type="range"
        :min="min"
        :max="max"
        @input="setLeftValue"
      />
      <input
        ref="rightRef"
        v-model="values[1]"
        type="range"
        :min="min"
        :max="max"
        @input="setRightValue"
      />

      <!-- 커스텀 슬라이더 -->
      <div class="range-slider">
        <div class="track"></div>
        <div class="range" :style="`left: ${left}%; right:${right}%`"></div>
        <div class="thumb left" :style="`left: ${left}%;`"></div>
        <div class="thumb right" :style="`right:${right}%`"></div>
      </div>
      <div v-show="isShowText" class="range-text-box">
        <span>0 WON</span>
        <span>{{ max.numberFormat() }} WON</span>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from 'vue-demi';
export default {
  props: {
    value: { default: [0, 5000000] },
    min: { default: 0 },
    max: { default: 5000000 },
    isShowText: { default: true },
  },
  setup(props, context) {
    const state = reactive({
      values: props.value,
      left: 0,
      right: 0,
      leftRef: null,
      rightRef: null,
    });

    const setInitLeft = () => {
      const [left, right] = state.values;
      const { min, max } = props;
      const values = [...state.values];

      if (left > right) {
        values[0] =
          Math.floor(Math.min(left, right - max * 0.01) / 1000) * 1000;
        state.values = values;
        return;
      }

      const minValue = (left - min) / (max - min);
      const percent = minValue * 100;

      values[0] = Math.floor((props.max * minValue) / 1000) * 1000;

      state.values = values;
      state.left = percent;
    };

    const setInitRight = () => {
      const [left, right] = state.values;
      const values = [...state.values];

      const { min, max } = props;

      if (left + max * 0.01 >= right) {
        values[1] =
          Math.floor(Math.max(right, left + max * 0.01) / 1000) * 1000;
        state.values = values;
        return;
      }

      // input, thumb 같이 움직이도록
      const maxValue = (right - min) / (max - min);
      const percent = maxValue * 100;

      values[1] = Math.floor((max * maxValue) / 1000) * 1000;

      state.values = values;
      state.right = 100 - percent;
    };
    setInitRight();
    setInitLeft();

    watch(
      () => props.value,
      () => {
        state.values = props.value;
        setInitRight();
        setInitLeft();
      },
    );

    return {
      ...toRefs(state),
      setLeftValue() {
        setInitLeft();
        context.emit('input', state.values);
      },
      setRightValue() {
        setInitRight();

        context.emit('input', state.values);
      },
    };
  },
};
</script>

<style scoped>
/* body {
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e5e5e5;
} */

.middle {
  position: relative;
  width: 100%;
}

.range-slider {
  position: relative;
  z-index: 1;
  height: 1px;
  margin: 0 15px;
}

.range-slider > .track {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 5px;
  background-color: #a8a8a8;
  height: 1px;
}

.range-slider > .range {
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  background-color: #202020;
  height: 1px;
}

.range-slider > .thumb {
  position: absolute;
  z-index: 3;
  width: 14px;
  height: 14px;
  top: 3px;
  background-color: #202020;
}

.range-slider > .thumb.left {
  left: 25%;
  transform: translate(-15px, -10px);
  margin-left: 4px;
}
.range-slider > .thumb.right {
  right: 25%;
  transform: translate(15px, -10px);
  margin-right: 4px;
}

.range-text-box {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.range-text-box span {
  font-size: 12px;
  color: #a8a8a8;
}

input[type='range'] {
  position: absolute;
  /* opacity로 가린 것을 이벤트도 비활성화하기 위해 */
  pointer-events: none;
  -webkit-appearance: none;
  z-index: 2;
  height: 10px;
  width: 100%;
  opacity: 0;
}

input[type='range']::-webkit-slider-thumb {
  /* 겹쳐진 두 thumb를 모두 활성화 */
  pointer-events: all;
  width: 30px;
  height: 30px;
  border-radius: 0;
  border: 0 none;
  background-color: red;
  cursor: pointer;
  /* appearance를 해야 위의 스타일들을 볼 수 있음 */
  -webkit-appearance: none;
}
</style>
